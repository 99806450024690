import React, { useEffect, useState } from "react";
import CardCleaningService from "../../Components/CardCleaningService/CardCleaningService";
import sampleImg from "../../Assets/CleaningService/card-3.png";
import cleaningServiceData from "../../Data/CleaningService.json";
interface CleaningServiceData {
  heading: string;
  description: string;
  image: string;
}

const CleaningServices = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const cardsToShow = 4; // Number of cards to show at once
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex + cardsToShow >= cleaningServiceData.length
          ? 0
          : prevIndex + cardsToShow
      );
    }, 6000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  // Function to go to a specific slide (showing 3 cards)
  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  // Get the current set of cards to display
  const getVisibleCards = () => {
    return cleaningServiceData.slice(currentIndex, currentIndex + cardsToShow);
  };
  return (
    <div className="relative flex flex-col items-center my-[40px] ">
      <div className=" mb-[30px]  font-bold text-[28px]">Cleaning Services</div>
      <p className=" text-[16px] font-light  text-black text-justify overflow-ellipsis">
        Comprehensive cleaning solutions for every space: From residential homes
        to commercial offices, we have you covered!
      </p>
      {/* Carousel */}
      <div className="py-[40px] w-full flex justify-center space-x-[40px]  overflow-x-hidden">
        {getVisibleCards().map((card, index) => (
          <CardCleaningService
            key={index}
            heading={card.heading}
            description={card.description}
            image={sampleImg} // Replace with `card.image` if using different images
          />
        ))}
      </div>

      {/* Dot navigation */}
      <div className="flex justify-center space-x-2">
        {Array.from({
          length: Math.ceil(cleaningServiceData.length / cardsToShow),
        }).map((_, index) => (
          <span
            key={index}
            className={`h-3 w-3 rounded-full cursor-pointer ${
              index === Math.floor(currentIndex / cardsToShow)
                ? "bg-black"
                : "bg-gray-400"
            }`}
            onClick={() => goToSlide(index * cardsToShow)}></span>
        ))}
      </div>
    </div>
  );
};
export default CleaningServices;
