import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home/Home";
import Services from "../Pages/Services/Services";
import About from "../Pages/About/About";
import Contact from "../Pages/Contact/Contact";
// Define the shape of each route object
interface RouteType {
  component: React.ComponentType;
  path: string;
}

// Define the routes list with type safety
const appRoutesList: RouteType[] = [
  {
    component: Home,
    path: "/",
  },
  {
    component: Services,
    path: "/services",
  },
  {
    component: About,
    path: "/about",
  },
  { component: Contact, path: "/contact" },
];

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {appRoutesList.map((route, index) => (
        <Route key={index} path={route.path} element={<route.component />} />
      ))}
    </Routes>
  );
};

export default AppRoutes;
