import React from "react";
import ReasonToChooseBg from "../../Assets/ReasonToChoose/21780_ 1.png";
import ReasonToChooseData from "../../Data/ReasonToChoose.json";
const ReasonsToChoose = () => {
  return (
    <div className="relative w-full h-[600px] my-[40px] ">
      <img
        src={ReasonToChooseBg}
        alt="Reason to choose us"
        className="w-full h-[600px]  object-cover"
      />

      <div className="absolute top-0 left-[45%] w-[50%] h-full flex flex-col text-left text-black">
        <div className="flex flex-col p-[35px] h-[100%] justify-evenly ">
          <div>
            <div className="mb-[30px] font-bold text-[28px]">
              Reasons to Choose Us
            </div>
            <div className="text-[16px] font-normal  text-black overflow-ellipsis">
              When it comes to selecting a cleaning partner for your commercial
              needs, the choice is clear. Rowles and James Cleaning Services
              stands out as the premier option for a multitude of compelling
              reasons:
            </div>
          </div>

          {ReasonToChooseData.map((reason, index) => (
            <div>
              <div className="mb-[10px] font-bold text-[18px]">
                {reason.heading}
              </div>
              <div className="text-[16px] font-normal  text-black overflow-ellipsis">
                {reason.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ReasonsToChoose;
