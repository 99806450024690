import React, { useState } from "react";

const FormBookService = () => {
  const [formData, setFormData] = useState({
    subject: "",
    fullAddress: "",
    firstName: "",
    lastName: "",
    postalCode: "",
    phone: "",
    message: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(formData); // You can handle form submission here
  };

  return (
    <div className="max-w-lg mx-auto p-[30px] bg-white rounded-[15px] shadow-md">
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Subject */}
        <div>
          <input
            placeholder="Subject"
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        {/* Full Address */}
        <div>
          <input
            placeholder="Full Address"
            type="text"
            name="fullAddress"
            value={formData.fullAddress}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>
        <div className="flex justify-between">
          {/* First Name */}
          <div>
            <input
              placeholder="First Name *"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
            />
          </div>
          {/* Last Name */}
          <div>
            {/*  <label className="block font-medium mb-2">
            Last Name <span className="text-red-600">*</span>
          </label> */}
            <input
              placeholder="Last Name "
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Postal Code */}
        <div>
          {/* <label className="block font-medium mb-2">
            Postal Code <span className="text-red-600">*</span>
          </label> */}
          <input
            placeholder="Postal Code"
            type="text"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        {/* Phone */}
        <div>
          {/* <label className="block font-medium mb-2">
            Phone <span className="text-red-600">*</span>
          </label> */}
          <input
            placeholder="Phone *"
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        {/* Message */}
        <div>
          {/* <label className="block font-medium mb-2">
            Message <span className="text-red-600">*</span>
          </label> */}
          <textarea
            placeholder="Message *"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border bg-gray-100 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
            rows={4}></textarea>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition-colors">
          Submit
        </button>
      </form>
    </div>
  );
};

export default FormBookService;
