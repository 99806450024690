import React, { FC, useState } from "react";
import cardImg from "../Assets/CleaningService/card-2.png";

interface CardCleaningServiceProps {
  heading: string;
  description: string;
  image: any;
}
const CardCleaningService: FC<CardCleaningServiceProps> = ({
  heading,
  description,
  image,
}) => {
  return (
    <div
      className="  w-[350px] h-[420px] rounded-[10px] object-contain bg-white transition-transform transform hover:-translate-y-[3px] shadow-xl hover:shadow-xl hover:bg-white duration-300 ease-in-out
 ">
      <div className=" w-[350px]  h-[200px]  rounded-[10px] object-contain">
        <img
          src={image}
          alt="Cleaning Service"
          className="w-[350px]  h-[200px]  rounded-t-[10px] "
        />
      </div>
      <div className="px-[20px]">
        <div className="text-[18px]  h-[50px] my-[10px] font-bold text-black text-center">
          {heading}
        </div>
        <p className=" h-[150px]   text-[16px] font-light  text-black text-justify overflow-ellipsis">
          {description}
        </p>
      </div>
    </div>
  );
};

export default CardCleaningService;
