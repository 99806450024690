import React from "react";
import CardCustomerReview from "../CardCustomerReview/CardCustomerReview";
import customerLogo from "../../Assets/CustomerReview/client-1.png";
import customerReview from "../../Data/CustomerReview.json";
const CustomerReviews = () => {
  return (
    <div>
      <div className="mb-[30px] font-bold text-[28px]">
        Words from Our Satisfied Customers
      </div>
      <div className="flex flex-col md:flex-row overflow-hidden">
        {customerReview.map((review) => (
          <CardCustomerReview
            heading={review.heading}
            description={review.description}
            image={customerLogo}
            starCount={review.starCount}
          />
        ))}
      </div>
    </div>
  );
};
export default CustomerReviews;
