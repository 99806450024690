import React from "react";
import facebook from "../../Assets/General/facebook.png";
import instagram from "../../Assets/General/instagram.png";
import tiktok from "../../Assets/General/tiktok.png";
import phone from "../../Assets/General/phone.png";
import email from "../../Assets/General/email.png";
import home from "../../Assets/General/home.png";
//Check deployment1
const Footer = () => {
  return (
    <div className="flex justify-between  bg-gray-800 h-auto py-[20px] mt-[40px] text-white text-left">
      <div className="flex-[.4] flex flex-col justify-center mx-[50px]">
        <div className="text-left mb-[20px]  font-bold text-[28px]">
          Absolute Shines Cleaning
        </div>
        <p className="mb-[20px] ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="flex flex-row justify-start object-center  object-contain ">
          <img
            className="h-[40px] w-[40px] mr-[10px] cursor-pointer"
            src={facebook}
          />
          <img
            className="h-[40px] w-[40px] mr-[10px] cursor-pointer"
            src={instagram}
          />
          <img
            className="h-[40px] w-[40px] mr-[10px] cursor-pointer"
            src={tiktok}
          />
        </div>
      </div>
      <div className="flex-[.4] flex flex-col justify-center mx-[50px]">
        <div className="text-left mb-[20px]  font-bold text-[28px]">
          Contact Information
        </div>
        <div className="mb-[20px] flex items-center">
          <img className="h-[25px] w-[25px] mr-[20px]" src={home} />
          No. 10, Flower Road, Colombo
        </div>
        <div className="mb-[20px] flex items-center">
          <img className="h-[25px] w-[25px] mr-[20px]" src={email} />
          companyemail@company.com
        </div>
        <div className="mb-[20px] flex items-center">
          <img className="h-[25px] w-[25px] mr-[20px]" src={phone} />
          0112-555-55
        </div>
      </div>
    </div>
  );
};
export default Footer;
