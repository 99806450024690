import React from "react";

const ServiceArea = () => {
  return (
    <div>
      <div className="text-left mb-[30px]  font-bold text-[28px]">
        Our Service Area
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d276438.561634865!2d144.84185995636247!3d-37.767714621403265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad646b5d2ba4df7%3A0x4045675218ccd90!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2slk!4v1726513086776!5m2!1sen!2slk"
          className="w-full h-[500px] border-none"
          loading="lazy"></iframe>
      </div>
    </div>
  );
};

export default ServiceArea;
