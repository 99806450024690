import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
const Services = () => {
  return (
    <div>
      <Navbar />
      Services
    </div>
  );
};
export default Services;
