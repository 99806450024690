import React from "react";
import FormBookService from "../CardCleaningService/FormBookService";
const BookService = () => {
  return (
    <div className="flex bg-gray-100 h-auto p-[30px]">
      <div className=" flex-[.5]">
        <FormBookService />
      </div>
      <div className="flex-[.5] flex flex-col justify-start items-center text-justify">
        <div className="px-[30px]">
          <div className="text-left mb-[30px]  font-bold text-[28px]">
            Send us your requests
          </div>
          <p className="mb-[30px] text-justify font-semibold text-[20px]">
            We excel in providing top-tier commercial cleaning services as
            industry leaders
          </p>
          <p className="text-[16px] text-justify font-normal  text-black overflow-ellipsis ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
      </div>
    </div>
  );
};
export default BookService;
