import React from "react";
import custtomerLogo from "../../Assets/CustomerReview/client-1.png";
import star from "../../Assets/General/star.png";

interface CardCustomerReviewProps {
  heading: string;
  description: string;
  image: string;
  starCount: number;
}

const CardCustomerReview = ({
  heading,
  description,
  image,
  starCount,
}: CardCustomerReviewProps) => {
  const GetStars = (starCount: number) => {
    let stars = [];
    for (let i = 0; i < starCount; i++) {
      stars.push(<img src={star} />);
    }
    return stars;
  };

  return (
    <div className="flex flex-col w-[350px] h-[300px] my-[40px] mr-[40px]  rounded-[10px] transition-transform transform hover:-translate-y-[3px] shadow-2xl hover:shadow-xl hover:bg-white duration-300 ease-in-out ">
      <div className="flex-[.6]  flex flex-col items-center justify-center w-[350px] rounded-t-[5px]">
        <img className="h-[80px] w-[80px]" src={image} />
        <div>{heading}</div>
        <div className="flex flex-row items-center justify-center h-[20px] w-[20px]">
          {GetStars(starCount)}
        </div>
      </div>
      <div className="flex-[.4] w-[350px]  bg-gray-100 p-[20px] text-[16px] text-justify font-normal  text-black overflow-ellipsis rounded-b-[5px]">
        {description}
      </div>
    </div>
  );
};

export default CardCustomerReview;
