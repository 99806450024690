import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
const About = () => {
  return (
    <div>
      <Navbar />
      About
    </div>
  );
};
export default About;
